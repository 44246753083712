<template>
    <div class="agendaroom-comment">
        <CompTable isSelector ref="comp_table" :title="discuss ? '评议管理' : '评价审核'" :columns="columns" :tableApi="tableApi">
            <template #operate>
                <RadioGroup v-if="discuss" v-model="activity" type="button" button-style="solid">
                    <Radio :label="0">屏蔽</Radio>
                    <Radio :label="1">显示</Radio>
                </RadioGroup>
            </template>
        </CompTable>

        <Preview ref="preview"></Preview>
    </div>
</template>

<script>
import CompTable from "../jointly/components/comp-table"
import Preview from "../comment/components/Preview.vue"

import Route from "../communitymobilization/entity/Route"
import Request from "../jointly/utils/request"

export default {
    components: { CompTable, Preview },

    data() {
        return {
            tableApi: null,
            columns: [
                {
                    title: "内容",
                    render: (h, params) => {
                        const files = []

                        const images = params.row.photo?.split(",")

                        images?.forEach(item => {
                            if (!item) return
                            files.push(
                                h("div", {
                                    style: {
                                        width: "50px",
                                        height: "50px",
                                        margin: "3px",
                                        background: `url(${item})`,
                                        "background-size": "cover",
                                        "background-position": "center",
                                        "border-radius": "5px",
                                        cursor: "pointer",
                                    },
                                    on: {
                                        click: () => {
                                            this.$refs.preview.display(item, images)
                                        },
                                    },
                                })
                            )
                        })

                        return h(
                            "div",
                            {
                                style: {
                                    margin: "10px 0",
                                },
                            },
                            [
                                h(
                                    "p",
                                    {
                                        style: {
                                            "text-align": "center",
                                        },
                                    },
                                    params.row.content || "-"
                                ),
                                ...(files.length > 0
                                    ? [
                                          h(
                                              "div",
                                              {
                                                  style: {
                                                      display: "flex",
                                                      "justify-content": "center",
                                                      "flex-wrap": "wrap",
                                                      "margin-top": "5px",
                                                  },
                                              },
                                              files
                                          ),
                                      ]
                                    : []),
                            ]
                        )
                    },
                },
                {
                    title: "日期",
                    render: (h, params) => {
                        const date = new Date(params.row.gmtCreate)
                        return this.$core.formatDate(date, (date.getFullYear() !== new Date().getFullYear() ? "yyyy年" : "") + "MM月dd日")
                    },
                },
                {
                    title: "来源",
                    key: "title",
                    // style: {
                    //     color: "#2d8cf0",
                    //     cursor: "pointer",
                    //     "text-decoration": "underline",
                    // },
                    // click: params => {
                    //     Route.jump("/agendaroomevolve", { id: params.row.infoId })
                    // },
                },
                {
                    title: "状态",
                    render: (h, params) => {
                        return h(
                            "RadioGroup",
                            {
                                props: {
                                    value: params.row.status == 2 ? "1" : "0",
                                },
                                on: {
                                    "on-change": () => {
                                        Request.post("/gateway/syinfopublish/api/app/infoComments/updateDisplayStatus", {
                                            id: params.row.id,
                                        }).then(() => {
                                            this.$Message.success("修改成功")
                                            // 刷新列表
                                            this.$refs.comp_table.refresh()
                                        })
                                    },
                                },
                            },
                            [
                                h(
                                    "Radio",
                                    {
                                        attrs: {
                                            label: "0",
                                        },
                                    },
                                    "屏蔽"
                                ),
                                h(
                                    "Radio",
                                    {
                                        attrs: {
                                            label: "1",
                                        },
                                    },
                                    "公开"
                                ),
                            ]
                        )
                        // const date = new Date(params.row.gmtCreate)
                        // return this.$core.formatDate(date, (date.getFullYear() !== new Date().getFullYear() ? "yyyy年" : "") + "MM月dd日")
                    },
                },
            ],
            // 评议
            discuss: false,
            activity: 0,
        }
    },

    watch: {
        activity(v) {
            this.setTableUrl()
        },
    },

    created() {
        this.discuss = this.$core.getUrlParam("type") === "discuss"
        this.setTableUrl()
    },

    methods: {
        setTableUrl() {
            var url = `/gateway/syinfopublish/api/app/infoComments/listComments?infoType=PLACENAME&orgCode=`

            if (this.discuss) {
                url += `&infoId=${this.$core.getUrlParam("id")}&status=${this.activity === 0 ? 1 : 2}`
            } else {
                url += `&status=1&excludeOneself=2`
            }

            this.tableApi = url
        },
    },
}
</script>
<style lang="less">
.agendaroom-comment {
    .menu-bar-box {
        height: 45px;
        margin: 10px 10px 20px 10px;

        .menu-box {
            position: relative;
            width: 100%;
            height: 100%;
            border: 1px solid #f3f3f3;
            background: #fff;
            border-radius: 5px;
            display: flex;

            .slider {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                width: 50%;
                transition: all 0.3s ease;

                &::after {
                    content: "";
                    background: #2faaf7;
                    position: absolute;
                    top: 5px;
                    left: 5px;
                    bottom: 5px;
                    right: 5px;
                    border-radius: 3px;
                }
            }

            > .item-box {
                position: relative;
                height: 100%;
                z-index: 10;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.3s ease;
                padding: 0 20px;
            }

            > .activity {
                color: #fff;
            }
        }
    }
}
</style>
